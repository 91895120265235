import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import CustomerFollow from '../followup/customerfollow';

import { assignFollowUpId } from '../redux/actions';
import { assignControlId } from '../redux/actions';
import { assignWorkStatusId } from '../redux/actions';

import Server from '../function/server';

class NavbarPage extends Component {

  state = {
    isOpen: false, control: []
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getStyle = (type) => {
    let { controls } = this.props, style = {};
    controls = (!controls || controls.length < 1) ? "admin" : controls.toLowerCase();
    if (controls.includes("admin") || controls.includes(type)) return {};
    return {display: 'none'};
  }

  render() {
    let { assignFollowUpId } = this.props;
    let { assignControlId } = this.props;
    let { assignWorkStatusId } = this.props;
    return (
        <MDBNavbar style={{backgroundColor: '#2185d0'}} dark expand="md">
          <MDBNavbarBrand>
            <strong className="white-text">Customer Relationship Management</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              <MDBNavItem style={{margin: 8}}>
                <Link to="/" style={{color: 'white'}}>Home</Link>
              </MDBNavItem>
              <MDBNavItem style={this.getStyle("master")}>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2">Master</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <Link to="/customers/active">
                      <MDBDropdownItem>Customers</MDBDropdownItem>
                    </Link>
                    <Link to="/customers/inactive">
                      <MDBDropdownItem>Leads</MDBDropdownItem>
                    </Link>
                    <Link to="/primary-categories">
                      <MDBDropdownItem>Primary Category</MDBDropdownItem>
                    </Link>
                    <Link to="/secondary-categories">
                      <MDBDropdownItem>Secondary Category</MDBDropdownItem>
                    </Link>
                    <Link to="/enquiry-types">
                      <MDBDropdownItem>Enquiry Types</MDBDropdownItem>
                    </Link>
                    <Link to="/work-type">
                      <MDBDropdownItem>Work Type</MDBDropdownItem>
                    </Link>
                    <Link to="/group">
                      <MDBDropdownItem>Groups</MDBDropdownItem>
                    </Link>
                    <Link to="/field">
                      <MDBDropdownItem>Fields</MDBDropdownItem>
                    </Link>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
               <MDBNavItem style={this.getStyle("follow up")}>
                 <MDBDropdown>
                 <MDBDropdownToggle nav caret>
                   <span className="mr-2">Enquiry</span>
                 </MDBDropdownToggle>
                 <MDBDropdownMenu>
                   <Link to="/customer-enquiry/add" onClick={() => assignFollowUpId(0)}>
                     <MDBDropdownItem>Create Customers Enquiry</MDBDropdownItem>
                   </Link>
                   <Link to="/walkin-enquiry/add" onClick={() => assignFollowUpId(0)}>
                     <MDBDropdownItem>Create Lead Enquiry</MDBDropdownItem>
                   </Link>
                   <Link to="/customer-enquiry" onClick={() => assignFollowUpId(0)}>
                     <MDBDropdownItem>View Customer Enquiry</MDBDropdownItem>
                   </Link>
                   <Link to="/walkin-enquiry">
                     <MDBDropdownItem>View Lead Enquiry</MDBDropdownItem>
                   </Link>
                 </MDBDropdownMenu>
                </MDBDropdown>
               </MDBNavItem>
               <MDBNavItem style={this.getStyle("work status")}>
                 <MDBDropdown>
                 <MDBDropdownToggle nav caret>
                   <span className="mr-2">Work Status</span>
                 </MDBDropdownToggle>
                 <MDBDropdownMenu>
                   <Link to="/work-status/add" onClick={() => assignWorkStatusId(0)}>
                     <MDBDropdownItem>Create</MDBDropdownItem>
                   </Link>
                   <Link to="/work-status">
                     <MDBDropdownItem>View Work Status</MDBDropdownItem>
                   </Link>
                 </MDBDropdownMenu>
                </MDBDropdown>
               </MDBNavItem>
               <MDBNavItem style={this.getStyle("controls")}>
                 <MDBDropdown>
                 <MDBDropdownToggle nav caret>
                   <span className="mr-2">Controls</span>
                 </MDBDropdownToggle>
                 <MDBDropdownMenu>
                   <Link to="/control/add" onClick={() => assignControlId()}>
                     <MDBDropdownItem>Create</MDBDropdownItem>
                   </Link>
                   <Link to="/control">
                     <MDBDropdownItem>View Controls</MDBDropdownItem>
                   </Link>
                 </MDBDropdownMenu>
                </MDBDropdown>
               </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      );
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignFollowUpId: (id) => dispatch(assignFollowUpId(id)),
  assignControlId: (user_id) => dispatch(assignControlId(user_id)),
  assignWorkStatusId: (id) => dispatch(assignWorkStatusId(id)),
});

export default connect(null, mapDispatchToProps)(NavbarPage);
