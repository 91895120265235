import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../function/server';
import UserId from '../function/userid';
import Shop from '../function/shop';
import Load from '../util/load';

class AddControl extends Component {

  state = { user: [] }

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    let { controlId } = this.props;
    if (!isNaN(controlId) && controlId > 0) {
      this.getControl(controlId);
    }
  }

  getControl = (userId) => {
    let url = Server() + "control/getbyid.php";
    $.ajax({url, method: "POST", data: {user_id: userId}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {user_id, control} = c;
      this.setState({user_id, control, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let { user_id, control } = this. state;
    if( !user_id || user_id.length < 1 || control.length < 0 ) {
      alert("Invalid Information"); return;
    }
    let data = { user_id, shop_id: Shop().id, control, created_by: UserId() };
    let url = Server() + "control/add.php";
    if (this.state.edit) {
      data = { user_id, control };
      url = Server() + "control/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if (data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Control Added Successfully");
        this.setState({user_id: "", control: "", load: false});
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  getIdFromMail = () => {
    let { mail } = this.state;
    if (!mail || mail.length < 1) return;
    let url = Server() + "control/getidfrommail.php";
    let data = { mail };
    $.ajax({url, method: "POST", data, success: (data) => {
      if (!isNaN(data) && data > 0) {
          this.setState({ user_id: data });
      }
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  render() {
    let controlOptions = [{key: 0, value: "Admin", text: "Admin"}, {key: 1, value: "Master", text: "Master"},
                        {key: 2, value: "Follow Up", text: "Follow Up"}, {key: 3, value: "Followup Admin", text: "Followup Admin"},
                        {key: 4, value: "Work Status", text: "Work Status"}, {key: 5, value: "Workstatus Admin", text: "Workstatus Admin" },
                        {key: 6, value: "Controls", text: "Controls"}];
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <center>
            {
              this.state.edit != true &&
              <div>
                <Form.Input label='E-Mail' placeholder='E-Mail Id' value={this.state.mail}
                  onChange={this.handleChange("mail")} width={3}/>
                <Button onClick={this.getIdFromMail}>Find</Button><br /><br />
              </div>
            }
            <Form.Input fluid label='User Id' placeholder='Enter UserId'
              value={this.state.user_id} onChange={this.handleChange("user_id")} width={3} />
            <Form.Select label='Controls' placeholder='Choose Controls' clearable multiple
              value={this.state.control} onChange={this.handleChange("control")} options={controlOptions} width={3}/>
            <Button color= "blue" primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  controlId: state.controlId,
});

export default connect(mapStateToProps, null)(AddControl);
