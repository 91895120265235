import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Icon } from 'semantic-ui-react';
import $ from 'jquery';
import { connect } from 'react-redux';

import Shop from '../function/shop';
import Server from '../function/server';
import UserId from '../function/userid';
import Load from '../util/load';
import CustomerFollow from './customerfollow';

import { assignCustomerId } from '../redux/actions';

class AddFollowUp extends Component {

  state = { customers: [], user: [], customer: [] }

  componentDidMount() {
    this.getCustomerId();
    this.getUser();
    let { followUpId } = this.props;
    if (!isNaN(followUpId) && followUpId > 0) {
      this.getFollowUp(followUpId);
    }
  }

  componentDidUpdate(props) {
    if (props.status != this.props.status) {
      this.getCustomerId();
    }
  }

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  getFollowUp = (id) => {
    let url = Server() + "followup/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, customer_id, title, description, followup_by, start_date, next_follow_up, status} = c;
      start_date = start_date.split(" ")[0];
      next_follow_up = next_follow_up.split(" ")[0];
      this.setState({id, customer: customer_id, title, description, followup_by, start_date, next_follow_up, status, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getCustomerId = () => {
    let { customer } = this.props;
    let url = Server() + "customer/getallbyshop.php";
    let data = {id: Shop().id, status: this.props.status};
    $.ajax({url, method: "POST", data, success: (data) => {
      let customers = JSON.parse(data);
      this.setState({ customers, customer });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getUser = () => {
    let url = Server() + "users/getallbyuser.php";
    let data = { shop_id: Shop().id };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading"});
    }, success: (data) => {
      this.setState({load: false});
      let user = JSON.parse(data);
      this.setState({ user });
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  add = () => {
    let { customer, title, description, followup_by, start_date, next_follow_up, status} = this.state;
    if (!followup_by || followup_by.length < 1) {
      followup_by = -1;
    }
    if (customer < 1 || !title || title.length < 1 || !followup_by || followup_by.length < 1 || !description || description.length < 1 || !start_date ||
      start_date.length < 1 || !next_follow_up || next_follow_up.length < 1 || status.length < 0) {
    alert("Invalid Information"); return;
    }
    let data = { customer_id: customer, title, description, followup_by, start_date, next_follow_up, status, created_by: UserId()};
    let url = Server() + "followup/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, customer_id: customer, title, description, followup_by, start_date, next_follow_up, status };
      url = Server() + "followup/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if(data == "1"|| data == "true") {
        alert(this.state.edit ? "Updated successfully" : "Information Added Successfully");
        this.setState({title: "", description: "", followup_by: "", start_date: "", next_follow_up: "", status: "", customer: "", load: false});
        if (this.state.edit) {
          window.history.go(-1);
        }
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  appendCustomer = (customer) => {
    let { customers } = this.state;
    customers.push(customer);
    this.setState({customer: customer.id, customers});
  }

  render() {
    let { assignCustomerId } = this.props;
    let customerOptions = this.state.customers.map((c) => {
      return {key: c.id, value: c.id, text: c.name}
    });
    let followOptions = this.state.user.map((u) => {
      return {key: u.id, value: u.user_id, text: u.user_name}
    });
    let statusOptions = [{key: 0, value: "0", text: "Open"}, {key: 1, text: "Processing", value: "1"}, {key: 2, text: "Closed", value: "2"}];
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          {
            this.state.edit == true ?
             <div style={{ float: 'right', marginTop: '2%'}}>
               <Link to="/customers/edit" onClick={() => assignCustomerId(this.state.customer)}>
                 <Button primary>Convert To Customer</Button>
               </Link>
             </div> :
             <div style={{ float: 'right'}}>
                <CustomerFollow walkin={this.props.walkin} status={this.props.status} appendCustomer={this.appendCustomer}/>
             </div>
          }
          <Form.Group>
            <Form.Select fluid label='Customer' placeholder='Choose Customer' clearable
              value={this.state.customer} onChange={this.handleChange("customer")} options={customerOptions} width={4}/>
            <Form.Select fluid label='Follow By' placeholder='Choose User' clearable
              value={this.state.followup_by} onChange={this.handleChange("followup_by")} options={followOptions} width={4}/>
          </Form.Group>
          <Form.Input fluid label='Title' placeholder='Enter Title'
            value={this.state.title} onChange={this.handleChange("title")}/>
          <Form.TextArea label='Description' placeholder='Description'
            value={this.state.description} onChange={this.handleChange("description")}/>
          <Form.Group widths='equals'>
            <Form.Input fluid label='Start Date' placeholder='choose Starting Date' type="date"
              value={this.state.start_date} onChange={this.handleChange("start_date")} width={4}/>
            <Form.Input fluid label='Next Followup' placeholder='Enter Next Followup Date' type="date"
              value={this.state.next_follow_up} onChange={this.handleChange("next_follow_up")} width={4}/>
          </Form.Group>
          <Form.Select fluid label='Status' placeholder='Status' clearable
            onChange={this.handleChange("status")} value={this.state.status} options={statusOptions} width={4}/>
          <center>
            <Button color= 'blue' primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  followUpId: state.followUpId,
});

const mapDispatchToProps = (dispatch) => ({
  assignCustomerId: (id) => dispatch(assignCustomerId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AddFollowUp);
