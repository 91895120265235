import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

import { assignWorkTypeId } from '../../redux/actions';

class WorkType extends Component {

  state = {data: []}

  componentDidMount() {
    this.getWork();
  }

  getWork = () => {
    let url = Server() + 'worktype/getallbyshop.php';
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading WorkType"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({data});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  render() {
    let { assignWorkTypeId } = this.props;
    let data = this.state.data.map((w) => {
      return(
        <MDBTableBody>
          <tr>
            <td>{w.id}</td>
            <td>{w.type}</td>
            <td>{w.created_by}</td>
            <td>{w.timestamp}</td>
            <td><Link to="/work-type/edit" onClick={() => assignWorkTypeId(w.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          </tr>
        </MDBTableBody>
      )
    })
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/work-type/add" style={{float: 'right', marginBottom: 10}} onClick={() => assignWorkTypeId(0)}>
          <Button icon labelPosition='left' primary><Icon name='user' />Add Work Type</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>ID</th>
                <th>Work Type</th>
                <th>Created By</th>
                <th>Added on</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignWorkTypeId: (id) => dispatch(assignWorkTypeId(id)),
});

export default connect(null, mapDispatchToProps)(WorkType);
