import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddWork extends Component {

  state = {}

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    let { workTypeId } = this.props;
    if (!isNaN(workTypeId) && workTypeId > 0) {
      this.getWorkType(workTypeId);
    }
  }

  getWorkType = (id) => {
    let url = Server() + "worktype/getbyid.php";
    $.ajax({url, method: "POST", data: { id }, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let { type, id } = c;
      this.setState({type, id, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  add = () => {
    let {type} = this.state;
    if(!type || type.length < 1) {
      alert("Invalid Type"); return;
    }
    let data = {shop_id: Shop().id, type, period: 'NULL', created_by: UserId()};
    let url = Server() + 'worktype/add.php';
    if (this.state.edit) {
      data = {id: this.state.id, type, period: 'NULL'};
      url = Server() + "worktype/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Work Type Added Successfully");
        this.setState({type: "", load: false});
      } else {
        alert("Failed to Add");
      }
      this.setState({ load: false});
    }, error: (e) => {
      console.log(e);
      alert("Error");
      this.setState({ load: false});
    }})
  }

  render() {
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <center>
            <Form.Input fluid label='Name' placeholder='Enter Work Type'
              value={this.state.type} onChange={this.handleChange("type")} width={3}/>
            <Button primary onClick={this.add}>{this.state.edit ? "Edit" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  workTypeId: state.workTypeId,
});

export default connect(mapStateToProps, null)(AddWork);
