import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../function/server';
import Shop from '../function/shop';
import UserId from '../function/userid';
import Load from '../util/load';

class AddWorkStatus extends Component {

  state = { customers: [], workTypes: [], user: [] }

  handleChange = (type) => (e, {value}) => {
    if (type == 'gstin') {
      let start_date = this.setDate(new Date(), 0);
      let restart_date = this.setDate(new Date(), value);
      this.setState({ start_date, restart_date })
    }
    this.setState({[type]: value});
  }

  componentDidMount() {
    this.getAddWorkInfo();
    this.getUser();
    let { workStatusId } = this.props;
    if (!isNaN(workStatusId) && workStatusId > 0) {
      this.getWorkType(workStatusId);
    }
  }

  setDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() + (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }


  getWorkType = (id) => {
    let url = Server() + "workstatus/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, customer_id, work_type, allocated_to, status, work_pending, title, start_date,
                  expected_close_date, restart_date} = c;
      start_date = start_date.split(" ")[0];
      expected_close_date = expected_close_date.split(" ")[0];
      restart_date = restart_date.split(" ")[0];
      this.setState({id, customer: customer_id, workType: work_type, allocated_to, status, work_pending, title, start_date,
                  expected_close_date, restart_date, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getAddWorkInfo = () => {
    let url = Server() + 'workstatus/getaddworkinfobycustomer.php';
    let data = { id: Shop().id };
    $.ajax({url, method: "POST", data, success: (data) => {
      data = JSON.parse(data);
      let customers = [], workTypes = [];
      data.map((d) => {
        if(d.type === "customer") customers.push(d);
        else workTypes.push(d);
      });
      this.setState({ customers, workTypes });
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  getUser = () => {
    let url = Server() + "users/getallbyuser.php";
    let data = { shop_id: Shop().id };
    $.ajax({url, method: "POST", data,beforeSend: () => {
      this.setState({ load: true, message: "Loading"});
    }, success: (data) => {
      let user = JSON.parse(data);
      this.setState({ user, load: false });
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  add = () => {
    let { customer, workType, allocated_to, status, work_pending, title, start_date, expected_close_date, restart_date } = this.state;
    if(  customer < 1 || !workType || workType.length < 1 || !status || status.length < 0 || !work_pending || work_pending.length < 1 ||
        !title || title.length < 1 || !start_date || start_date.length < 1 || !expected_close_date ||
        expected_close_date.length < 1 || !restart_date || restart_date.length < 1 ) {
      alert("Invalid Information"); return;
    }
    if (!allocated_to || allocated_to.length < 1) {
      allocated_to = -1;
    }
    let data = { customer_id: customer, work_type: workType, allocated_to, status, work_pending, title, start_date,
                expected_close_date, restart_date, created_by: UserId(), received: 0 };
    let url = Server() + 'workstatus/add.php';
    if (this.state.edit) {
      data = {id: this.state.id, customer_id: customer, work_type: workType, allocated_to, status, work_pending, title,
              start_date, expected_close_date, restart_date, received: 0};
      url = Server() + "workstatus/edit.php";
    }
    $.ajax({url, method: "POST", data,beforeSend: () => {
      this.setState({ load: true, message: "Adding"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Work Type Added Successfully");
        this.setState({ allocated_to: "", status: "", work_pending: "", title: "", start_date: "", expected_close_date: "",
          restart_date: "", customer: "", workType: "", status: "", load: false});
        if (this.state.edit) {
          window.history.go(-1);
        }
      } else {
        alert("Failed To Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let { gstin } = this.state;
    let customerOptions = this.state.customers.map((c) => {
      return {key: c.id, value: c.id, text: c.name};
    });
    let workTypeOptions = this.state.workTypes.map((w) => {
      return {key: w.id, value: w.id, text: w.name}
    });
    let allocatedOptions = this.state.user.map((u) => {
      return {key: u.id, value: u.user_id, text: u.user_name}
    });
    let statusOptions = [{key: 0, value: "0", text: "Open"}, {key: 1, text: "Processing", value: "1"}, {key: 2, text: "Closed", value: "2"}];
    let gstinOptions = [{key: 0, value: 30, text: "Monthly Ones"}, {key: 1, value: 90, text: "Quarterly Ones"},
       {key: 2, value: 365, text: "Yearly Ones"}]
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <Form.Select label='Customer Name' placeholder='Choose Customer' clearable
            value={this.state.customer} onChange={this.handleChange("customer")} options={customerOptions} width={4}/>
          <Form.Group>
            <Form.Input fluid label='Title' placeholder='Enter Title' clearable
                value={this.state.title} onChange={this.handleChange("title")} width={4}/>
            <Form.Select label='Work Type' placeholder='Choose Work Type' clearable
              value={this.state.workType} onChange={this.handleChange("workType")} options={workTypeOptions} width={4}/>
          </Form.Group>
          <Form.TextArea fluid label='Additional Details' placeholder='Enter additional details' clearable
              value={this.state.work_pending} onChange={this.handleChange("work_pending")} width={4}/>
          <Form.Group widths='4'>
            <Form.Input label='Starting Date' placeholder='Date' type="date"
              value={this.state.start_date} onChange={this.handleChange("start_date")}/>
            <Form.Input label='Expected close Date' placeholder='Date' clearable type="date"
              value={this.state.expected_close_date} onChange={this.handleChange("expected_close_date")}/>
            <Form.Input label='Restart Date' placeholder='Date' clearable type="date"
              value={this.state.restart_date} onChange={this.handleChange("restart_date")}/>
            <Form.Select label="Recurring Work" placeholder="Choose Recurring Work" clearable onChange={this.handleChange("gstin")}
              options={gstinOptions} width={4} />
          </Form.Group>
          <Form.Group>
            <Form.Select label='Status' placeholder='Choose Status' clearable
              value={this.state.status} onChange={this.handleChange("status")} options={statusOptions} width={4}/>
            <Form.Select label='Allocated To' placeholder='Choose Allocated' clearable
              value={this.state.allocated_to} onChange={this.handleChange("allocated_to")} options={allocatedOptions} width={4}/>
          </Form.Group>
          <center>
            <Form.Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Form.Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  workStatusId: state.workStatusId,
});

export default connect(mapStateToProps, null)(AddWorkStatus);
